<template>
	<main>
		<form @submit.prevent="manageCompany">
			<h4>Company Information</h4>
			<small class="text-muted">Information about the organization.</small>
			<hr class="my-4" />
			<div class="row">
				<div class="col-12">
					<div class="form-group">
						<label class="form-label"> Company name </label>
						<input v-model.trim="company.corporate_name" required type="text" class="form-control" />
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div class="form-group">
						<label class="form-label"> Role </label>
						<input v-model.trim="company.role" required type="text" class="form-control" />
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div class="form-group">
						<label class="form-label"> Estimated staff strength </label>
						<v-select v-model="company.staff_strength" class="form-group" :options="staffStengthList" placeholder="">
						</v-select>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div class="form-group">
						<label class="form-label"> How soon do you want to start? </label>
						<v-datepicker style="width: 100%" v-model="company.start_date" placeholder="Select start date"></v-datepicker>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div class="form-group">
						<label class="form-label"> Preferred package </label>
						<v-select v-model="company.package_type" class="form-group" :options="packageList" placeholder="">
						</v-select>
					</div>
				</div>
			</div>
			<div class="d-flex justify-content-end align-items-end">
				<button :disabled="!isFormValid || processing" type="submit"
					:class="!isFormValid || processing ? 'btn-secondary' : 'btn-primary'" class="btn mt-4 w-25">
					{{ processing ? 'Processing...' : 'Next' }}
					<span class="ml-2"><img src="@/assets/img/icons/forward-icon.png" alt="" /></span>
				</button>
			</div>
		</form>
	</main>
</template>

<script setup>
import { computed, ref } from 'vue'

// name: 'CompanyInformationForm',
defineProps({
  companyData: {
    type: Object
  }
})

const processing = ref(false)
const staffStengthList = ref(['0-50', '50-100', '100-500', 'More than 500'])
const packageList = ref(['Pay per seat', 'Pay per vehicle'])
const company = ref({
  role: '',
  staff_strength: '',
  package_type: '',
  start_date: '',
  corporate_name: ''
})

const emit = defineEmits(['next'])
const manageCompany = () => {
  emit('next', company.value)
}
const isFormValid = computed(() => {
  return !!(company.value.role &&
    company.value.staff_strength &&
    company.value.package_type &&
    company.value.start_date &&
    company.value.corporate_name)
})
</script>
